import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import { useGetCharacterQuery } from "../services";
import { GPT_MODEL_TYPES, ICharacterSettingInput } from "../types";
import { CharacterSettingSchema } from "../validations/character";
import useCharacterSettingSelectInput from "./useCharacterSettingSelectInput";

interface IProps {
    characterId: string | undefined;
    projectId: string | undefined;
}

const useCharacterSettingForm = ({ characterId, projectId }: IProps) => {
    const { data: cInfo, isLoading: cIsLoading } = useGetCharacterQuery(
        { id: characterId as string },
        { skip: !characterId }
    );
    const defaultValues: ICharacterSettingInput = {
        gpt_model_id: cInfo?.data.character.gpt_model.toString() as GPT_MODEL_TYPES | null,
        service_type: cInfo?.data.character.service_type.toString() ?? "0",
    };
    const methods = useForm<ICharacterSettingInput>({
        defaultValues,
        resolver: yupResolver(CharacterSettingSchema()),
    });
    const { handleFormSubmit, getCharacterLoading } = useCharacterSettingSelectInput({
        characterId: characterId as string,
        projectId: projectId as string,
        methods,
    });

    const gptModel = cInfo?.data?.character?.gpt_model ?? null;
    const serviceType = cInfo?.data?.character?.service_type ?? null;

    useEffect(() => {
        methods.reset({
            gpt_model_id: gptModel?.toString() as GPT_MODEL_TYPES | null,
            service_type: serviceType?.toString() || "",
        });
    }, [serviceType, methods, gptModel]);
    return {
        methods,
        defaultValues,
        cIsLoading,
        handleFormSubmit,
        getCharacterLoading,
    };
};

export default useCharacterSettingForm;
