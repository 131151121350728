import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { customToast } from "../../../components/notify";
import { useAppSelector } from "../../../hooks";
import { selectCurrentUser } from "../../authentication/services";
import { checkHasPermission } from "../../authentication/utils";
import { useGetCharacterQuery, useUpdateCharacterMutation } from "../services";
import { ICharacterSettingInput, IUpdateCharacterReq } from "../types";

interface IProps {
    characterId: string;
    projectId: string;
    methods: UseFormReturn<ICharacterSettingInput>;
}
const useCharacterSettingSelectInput = ({ characterId, projectId, methods }: IProps) => {
    const { t } = useTranslation();
    const currentRole = useAppSelector(selectCurrentUser)?.role;
    const hasPermission = checkHasPermission(currentRole, "gptModel", "update");
    const { data: characters, isLoading: getCharacterLoading } = useGetCharacterQuery({ id: characterId });
    const [updateCharacter] = useUpdateCharacterMutation();

    const preUpdateCharacter = characters?.data?.character;

    const updateCharacterData = {
        voice_id: preUpdateCharacter?.voice_id ?? "",
        name: preUpdateCharacter?.name ?? "",
        greeting: preUpdateCharacter?.greeting ?? "",
        img_gen_enabled: preUpdateCharacter?.img_gen_enabled ?? false,
        copyable: preUpdateCharacter?.copyable ?? false,
        avatar_file_name: preUpdateCharacter?.avatar_file_name,
        restrict_response: preUpdateCharacter?.restrict_response ?? false,
        description: preUpdateCharacter?.description,
        pitch: preUpdateCharacter?.pitch ?? 0,
        speed: preUpdateCharacter?.speed ?? 0,
        prompt: preUpdateCharacter?.prompt,
    };

    const serviceType = methods.watch("service_type");
    const gptModelId = methods.watch("gpt_model_id");

    const fetchUpdate = async (reqData: IUpdateCharacterReq) => {
        try {
            await updateCharacter(reqData).unwrap();
            customToast.success(t("COMMON.Successfully"));
        } catch (error) {
            if (error instanceof Error) {
                customToast.error(error.message);
            } else {
                customToast.error("Failed");
            }
        }
    };

    const handleFormSubmit = () => {
        if ((serviceType || gptModelId) && characterId && projectId && hasPermission) {
            fetchUpdate({
                ...updateCharacterData,
                id: characterId,
                project_id: projectId,
                service_type: Number(serviceType),
                gpt_model: Number(gptModelId) ?? 0,
            });
            methods.reset();
        }
    };

    return { hasPermission, getCharacterLoading, updateCharacterData, fetchUpdate, handleFormSubmit };
};

export default useCharacterSettingSelectInput;
